@font-face {
  font-family: "Pokemon Hollow";
  src: url('../font/pokemon_hollow.ttf');
  font-style: normal;
  font-weight: normal;
}

@font-face {
  font-family: "Pokemon Solid";
  src: url('../font/pokemon_solid.ttf');
  font-style: normal;
  font-weight: normal;
}
